<template lang="html">
    <div id="orderSteps" v-if="cartOrderSelection.modalPosition != '' && user.token != null">
        <div class="rel d-flex justify-content-center align-items-start align-content-start">
            <div class="floatRectangle mt-md-5 mt-0">
                <div class="rel d-flex flex-wrap justify-content-end align-items-start align-content-start pb-4" 
                :class="pending != 0 ? (pending == 1 ? 'out' : (pending == 2 ? 'in' : '')) : ''">


                    <div class="headerModal w-100 d-flex flex-wrap justify-content-start align-items-center align-content-center position-relative px-md-5 px-3 py-md-4 py-3"> <!-- barra superior de modal -->
                        <span class="titleSelect text-start ts-11 font-3 col-md-10 col-9">
                            {{cartOrderTransition[cartOrderSelection.modalPosition].title[lang]}}
                        </span>
                        <div @click="close()" class="closeButton h-100 col-md-2 col-3 font-7 d-flex align-items-center align-content-center justify-content-center" role="button">
                            <img v :src="a10" v-svg-inline>
                        </div>
                    </div>

                    <div class="w-100 position-relative px-md-5 px-3 mt-3 pt-2 d-flex justify-content-end flex-wrap align-items-start align-content-start">
                   
                        <template v-for="(option, opidx) in cartOrderTransition[cartOrderSelection.modalPosition].options"> <!-- listado de opciones que tiene la ventana/paso en este momento -->

                            <div 
                            v-if="option.show"
                            :enabled="option.enabled"
                            class="option mb-4 w-100 d-flex ps-3 pe-1 pe-sm-4 pe-md-0" 
                            :class="option.idOption == cartOrderSelection[cartOrderSelection.modalPosition].selected ? 'selected' : (cartOrderSelection[cartOrderSelection.modalPosition].selected != '' ? 'notSelected' : '') " 
                            @click="selectOption($event, option.idOption, cartOrderSelection.modalPosition, cartOrderTransition[cartOrderSelection.modalPosition].defValue, null, true)">
                            
                                <!-- opcion de checkbox internamente tiene anidado el contenido si es seleccionado -->
                                <data class="checkBoxArea pe-none">
                                    <div class="commonPageCheckbox">
                                        <input :id="opidx" type="checkbox" :checked="option.idOption == cartOrderSelection[cartOrderSelection.modalPosition].selected">
                                        <label :for="opidx" class="me-1 ">
                                        <span class="pe-none"><img :src="option.simbol"></span></label>
                                    </div>
                                </data>

                                <div class="dataArea content d-flex">

                                    <!-- lateral informacion de columna izquierda -->

                                    <div class="col-md-8 col-12 d-flex flex-wrap flex-column pe-3">

                                        <div class="titleOption w-100 py-3 text-start font-5 ps-2 pe-none">
                                            {{ option.description[lang] }}
                                            <template v-if="cartOrderSelection.modalPosition == 'schedule'">
                                                <template v-if="opidx == 1"> <!-- en caso de ser la ventana tiempo de entrega, personalizar texto dependiendo del tipo de envio seleccionado-->
                                                    <template v-if="cartOrderSelection.sendType.selected == 'delivery'">
                                                        {{phrases.f9[lang]}}
                                                    </template>
                                                    <template v-else>
                                                        {{phrases.f10[lang]}}
                                                    </template>
                                                </template>
                                            </template>
                                        </div>

                                        <div class="listDataOption w-100 flex-fill d-flex flex-wrap font-7 text-start ps-md-4 ps-2"> <!-- contenido de la opcion checkbox descripcion -->

                                            <template v-if="cartOrderSelection.modalPosition == 'sendType'">  <!-- tipo de envio -->

                                                <!-- delivery -->

                                                <template v-if="option.idOption == 'delivery' && cartOrderSelection[cartOrderSelection.modalPosition].selected == 'delivery'">

                                                    <!-- modal esta seleccionado para de -->

                                                    <template v-if="option.allow === 2"> <!-- allow 2 representa que todo en la direccion esta correcto -->
                                                        <template v-for="(dir, dirName) in user.data.homes">
                                                            <template v-if="dirName == cartOrderSelection[cartOrderSelection.modalPosition].value">

                                                                <div class='dirComplete w-100 mb-4' v-if="this.user.dataToken.data.level == 1">
                                                                    <span class="hiddenInfoLabel font-5" @click="returnToCarLater()" v-html="phrases.f34[lang]"></span>
                                                                </div>
                                                                
                                                                <span class='dirComplete'>

                                                                    <span v-if="this.user.dataToken.data.level == 1" class="colonyText d-flex flex-wrap flex-sm-nowrap">
                                                                        <span class="d-flex col-12 col-sm-auto">
                                                                        {{ dir.colony }}<span class="d-sm-inline d-none">&nbsp;-&nbsp;</span>
                                                                        </span>
                                                                        <span class="cpText font-1 d-flex flex-wrap">
                                                                            <span class="w-100">CP {{dir.zip}}</span>
                                                                            <span v-if="this.user.dataToken.data.level == 1" class="editButton font-7 mt-2" @click="returnToCarLater()"> {{phrases.f35[lang]}}
                                                                            </span>
                                                                        </span>
                                                                    </span><br>

                                                                    <span v-if="this.user.dataToken.data.level >= 2" class="colonyText">{{ dir.colony }} - <span class="cpText font-1">CP {{dir.zip}}<br></span>
                                                                    </span><br>
                                                                    

                                                                    <template v-if="this.user.dataToken.data.level >= 2">
                                                                        {{ dir.street }}
                                                                        <template v-if="dir.extNumber != ''">&nbsp;{{ dir.extNumber }}</template>
                                                                        <template v-if="dir.intNumber != ''">&nbsp;int:{{ dir.intNumber }}</template>
                                                                        <template v-if="dir.crossing != ''">&nbsp;{{ dir.crossing }}</template>
                                                                    </template>

                                                                </span>

                                                                <span class='dirComplete w-100' v-if="this.user.dataToken.data.level >= 2">
                                                                    <template v-if="dir.refer != ''">{{ dir.refer }}</template>
                                                                    <span class="editButton font-7" @click="returnToCarLater()">&nbsp;&nbsp;{{phrases.f1[lang]}}</span>
                                                                </span>

                                                            </template>
                                                        </template>
                                                    </template>
                                                    

                                                    <template v-else-if="option.allow === 1"> <!-- allow 1 usuario login -->
                                                        <template v-if="user.data.deliveryDir == '' || user.data.deliveryDir == undefined" > <!-- sin direccion -->
                                                            <span class="font-5">{{phrases.f22[lang]}}</span>
                                                            <span class="editButton font-5" @click="returnToCarLater()">{{phrases.f18[lang]}}</span> 
                                                        </template>
                                                        <template v-else> <!-- direccion sin cobertura de delivery  -->
                                                            <span class="font-5" @click="returnToCarLater()">{{phrases.f20[lang]}}</span>
                                                            <span class="editButton font-5" @click="returnToCarLater()">{{phrases.f21[lang]}}</span>
                                                        </template>
                                                    </template>

                                                    <template v-else-if="option.allow === 3"> <!-- direccion no corresponde con ciudad -->
                       
                                                        <span class="font-5">{{phrases.f32[lang]}}</span>
                                                        <span class="editButton font-5" @click="returnToCarLater()">{{phrases.f18[lang]}}</span> 
                                                    </template>

                                                    <template v-else-if="option.allow === 4"> <!-- direccion correcta pero sucursal cerrada -->
                                                        
                                                        <span class="font-5">{{phrases.f26[lang]}}</span>
                                                    </template>

                                                    <template v-else-if="option.allow === 5"> <!-- direccion correcta pero sucursal con error -->
                                                        
                                                        <span class="font-5">{{phrases.f27[lang]}}</span>
                                                    </template>
                                                    
                                                </template>

                                                <!-- pickup -->

                                                <template v-else-if="option.idOption == 'pickup' && cartOrderSelection[cartOrderSelection.modalPosition].selected == 'pickup'">
                                                    <span class="font-5 mb-3">{{phrases.f25[lang]}}</span>
                                                    <select-custom 
                                                    @click="stop($event)" 
                                                    :keyShow="'name'" 
                                                    :list="storesAvailables" 
                                                    :placeholder="phrases.f11[lang]"
                                                    @change="(selectedValue, e) => selectOption(e, option.idOption, cartOrderSelection.modalPosition, selectedValue)" :value="cartOrderSelection[cartOrderSelection.modalPosition].value">
                                                    </select-custom>
                                                            
                                                </template>

                                            </template>

                                            <template v-if="cartOrderSelection.modalPosition == 'schedule'">  <!-- tiempo de envio -->

                                                <!-- now -->

                                                <template v-if="option.idOption == 'now'">
                                                    
                                                    <span class="descriptionText font-2">
                                                        {{phrases.f6[lang]}}
                                                    </span>

                                                </template>

                                                <!-- await / programar -->

                                                <template v-else-if="option.idOption == 'await'">

                                                    <span class="descriptionText font-2 w-100">
                                                        {{phrases.f7[lang]}}
                                                    </span>

                                                    <div class="w-100 d-flex flex-wrap flex-md-nowrap mt-4" v-if="cartOrderSelection[cartOrderSelection.modalPosition].selected == 'await'">
                                                        <div class="col-12 col-md-7 d-flex me-2">
                                                            <select-custom 
                                                            @click="stop($event)" 
                                                            :list="getPickupDates()" 
                                                            @change="(selectedValue, e) => selectOption(e, option.idOption, cartOrderSelection.modalPosition, selectedValue, 'date')" 
                                                            :keyShow="'day'" 
                                                            :placeholder="phrases.f12[lang]"
                                                            :value="cartOrderSelection[cartOrderSelection.modalPosition].value.date" 
                                                            :type="'key'">
                                                            </select-custom>
                                                        </div>
                                                        <div v-if="Object.keys(getAvailableHours(cartOrderSelection[cartOrderSelection.modalPosition].value.date)).length > 0" class="col-12 col-md-5 mt-2 mt-md-0 mb-2 mb-md-0 d-flex" :class="cartOrderSelection[cartOrderSelection.modalPosition].value.date == '' ? 'pe-none' : ''">
                                                            <select-custom 
                                                            @click="stop($event)" 
                                                            :list="getAvailableHours(cartOrderSelection[cartOrderSelection.modalPosition].value.date)" 
                                                            @change="(selectedValue, e) => selectOption(e, option.idOption, cartOrderSelection.modalPosition, selectedValue, 'hour')" 
                                                            :keyShow="''" 
                                                            :placeholder="phrases.f13[lang]"
                                                            :value="cartOrderSelection[cartOrderSelection.modalPosition].value.hour" 
                                                            :type="'key'">
                                                            </select-custom>
                                                        </div>
                                                    </div>

                                                </template>

                                            </template>

                                            <template v-if="cartOrderSelection.modalPosition == 'cashMode'">  <!-- forma de pago -->

                                                    <span class="subtitled font-7 w-100">
                                                        {{phrases.f31[lang]}}
                                                    </span>

                                                    <input @input="numericsData($event, 'event')" @click="stop($event)" autocomplete="off" autocorrect="off" @change="($event)=> selectOption($event, option.idOption, cartOrderSelection.modalPosition, $event.target.value)" :placeholder="phrases.f17[lang]" :value="cartOrderSelection[cartOrderSelection.modalPosition].value" autocapitalize="off" spellcheck="false" class="commonMinInput w-100 px-3 font-7 mt-3 mb-3" maxlength="4" type="text">

                                            </template>

                                        </div>
                                    </div>

                                    <!-- lateral informacion de columna derecha -->

                                    <div class="timeIndicator col-4 d-none d-md-flex flex-wrap align-content-start align-items-start pt-0 align-items-md-start align-content-md-start">

                                        <template v-if="cartOrderSelection.modalPosition == 'sendType'"> <!-- tipo de envio -->
                                            <div class="timeLabel font-7 w-100 pt-md-4 pt-0 "> <!-- texto superior -->
                                                <template v-if="option.idOption == 'delivery' && option.allow === 2"> <!-- delivery y direccion en correctas condiciones -->
                                                    40”
                                                </template>
                                                <template v-else-if="option.idOption == 'delivery' && (option.allow == 1 ||  option.allow == 3 ||  option.allow == 5)"> <!-- delivery y sin direccion -->
                                                    <img :src="a48" class="mb-2">
                                                </template>
                                                <template v-else-if="option.idOption == 'pickup'"> <!-- pasar a tienda -->
                                                    15”
                                                </template>
                                            </div>
                                            <div class="subTimeLabel font-1 w-100" v-if="option.enabled"> <!-- texto inferior -->
                                                <template v-if="option.idOption == 'delivery' && (option.allow == 1 ||  option.allow == 3 ||  option.allow == 5)"> <!-- delivery y sin direccion -->
                                                    <template v-if="Object.keys(user.data.homes).length <= 0">{{ phrases.f23[lang] }}</template> 
                                                    <template v-else>{{ phrases.f24[lang] }}</template>
                                                </template>
                                                <template v-else> <!-- todo correcto -->
                                                    {{ phrases.f4[lang] }}
                                                </template>
                                            </div>
                                        </template>

                                        <template v-if="cartOrderSelection.modalPosition == 'schedule'"> <!-- tiempo de envio -->
                                            <div class="timeLabel font-7 w-100 pt-md-4 pt-0 ">
                                                <template v-if="option.idOption == 'now'"> <!-- ahora -->
                                                   <template v-if="cartOrderSelection.sendType.selected == 'pickup' "> 15” </template>
                                                   <template v-else> 40” </template>
                                                </template>
                                                <template v-if="option.idOption == 'await'"> <!-- ahora -->
                                                    <img :src="a49" class="mb-2 iconOption">
                                                 </template>
                                            </div>
                                            <div class="subTimeLabel font-1 w-100" v-if="option.idOption == 'now'">
                                                <template v-if="cartOrderSelection.sendType.selected == 'pickup' "> {{ phrases.f4[lang] }} </template>
                                                <template v-else> {{ phrases.f5[lang] }} </template>
                                            </div>
                                            <div class="subTimeLabel font-1 w-100" v-if="option.idOption == 'await'">
                                                {{ phrases.f33[lang] }}
                                            </div>
                                        </template>

                                        <template v-if="cartOrderSelection.modalPosition == 'payForm'"> <!-- forma de pago -->
                                            <div class="iconSpace font-7 w-100 d-flex justify-content-end pe-none">
                                                <img v-svg-inline class="posIcon" :src="option.icon"  :key="opidx">
                                            </div>
                                        </template>

                                    </div>

                                </div>

                            </div>

                        </template> 


                        <div class="commonPageButton buttonNext pushable active font-9 px-md-4 px-5 py-3 py-md-2 me-md-4 me-0 mt-4" @click="nextStep()" v-if="pending == 0 && validateNextness()"> <!-- valida si es posible continuar en el wizard (boton de continuar al siguiente paso) -->

                            <template v-if="cartOrderSelection.modalPosition == 'payForm' && cartOrderSelection[cartOrderSelection.modalPosition].selected == 'card' || cartOrderSelection.modalPosition == 'cashMode' || cartOrderSelection.modalPosition == 'onlineMode'"><span class="placeOrder">{{phrases.f3[lang]}}</span></template>

                            <template v-else>{{phrases.f2[lang]}}</template>

                        </div>


                        <div class="commonPageButton buttonNext pushable active font-9 px-md-4 px-4 py-2 py-md-2 me-md-4 me-0 mt-4" @click="returnToCarLater()" v-if="cartOrderSelection.modalPosition == 'sendType' && cartOrderSelection[cartOrderSelection.modalPosition].selected == 'delivery' && ((this.cartOrderTransition.sendType.options[0].allow === 1 && Object.keys(user.data.homes).length <= 0) || this.cartOrderTransition.sendType.options[0].allow === 3)">
                            {{phrases.f19[lang]}}
                        </div>

                        <template v-if="cartOrderSelection.modalPosition == 'storeClosed'">  <!-- mensaje de tienda cerrada -->
                            <div class="option mb-4 w-100 d-flex ps-3 pe-3 pe-md-0" >
                                <div class="w-100 content d-flex justify-content-center borderedBottom">
                                    <div class="col-md-10 col-12 d-flex flex-wrap flex-column pe-3">
                                        <div class="titleOptionError w-100 py-3 text-start font-8 fw-bold px-0">
                                            {{phrases.f29[lang]}}
                                        </div>
                                        <div class="listDataOption w-100 flex-fill d-flex flex-wrap font-7 text-start ps-2 pe-0 mb-3">
                                            <span class="descriptionText font-2">
                                                {{phrases.f26[lang]}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="commonPageButton buttonNext pushable active font-9 px-5 py-3 py-md-2 me-md-4 me-0 mt-4" @click="nextStep()">
                                {{phrases.f2[lang]}}
                            </div>
                        </template>

                        <template v-if="cartOrderSelection.modalPosition == 'storeError'">  <!-- mensaje de tienda offline -->
                            <div class="option mb-4 w-100 d-flex ps-3 pe-3 pe-md-0" >
                                <div class="w-100 content d-flex justify-content-center borderedBottom">
                                    <div class="col-md-10 col-12 d-flex flex-wrap flex-column pe-3">
                                        <div class="titleOptionError fw-bold w-100 py-3 text-start font-12 px-0">
                                            {{phrases.f30[lang]}}
                                        </div>
                                        <div class="listDataOption w-100 flex-fill d-flex flex-wrap font-7 text-start ps-2 pe-0">
                                            <span class="descriptionText font-2">
                                                {{phrases.f27[lang]}}
                                            </span>
                                        </div>
                                        <div class="callcenterText w-100 py-3 text-start font-9 ps-2 pe-0">
                                            800 BOSTONS - 800 267-86-67
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="commonPageButton buttonNext pushable active font-9 px-5 py-3 py-md-2 me-md-4 me-0 mt-4" @click="nextStep()">
                                {{phrases.f28[lang]}}
                            </div>
                        </template>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>

import selectCustom from "@/components/responsive/structures/fillStructures/selectCustom.vue"

export default {    
    name:"citySelector",
    emits:['close', 'event'],
    components:{
        selectCustom
    },
    props:{
        lang:{
            default:"es",
            type: String
        },
        user:{
            type:Object,
            default:{}
        },
        cityMenu:{
            default:{},
            type: Object
        },
        cities:{
            default:[],
            type: Array
        },
        socket:{
            default:{},
            type: Object
        },
        con:{
            default:{},
            type: Object
        },
        transfer:{
            type:Object,
            default:{}
        },
        cartOrderSelection:{
            default:{},
            type: Object
        },
        listStoreStatus:{
            default:{},
            type: Object
        },
    },
    watch:{
        pending(){ // actualizar objetos de control
            this.cartOrderTransition.sendType.options[0].allow = this.allowDelivery();
            this.cartOrderTransition.schedule.options[0].enabled = this.isOpenUserStore();
            this.cartOrderTransition.schedule.options[0].show = this.isOpenUserStore();
        },
        listStoreStatus(){ // cambio de status de las tiendas
            this.listOnline();
            this.cartOrderTransition.sendType.options[0].allow = this.allowDelivery();
            this.cartOrderTransition.schedule.options[0].enabled = this.isOpenUserStore();
            this.cartOrderTransition.schedule.options[0].show = this.isOpenUserStore();
        },
        user(){ //cambio del objeto usuario
            this.cartOrderTransition.sendType.options[0].allow = this.allowDelivery(); //actualiza la disponibilidad de delivery
            this.cartOrderTransition.schedule.options[0].enabled = this.isOpenUserStore();
            this.cartOrderTransition.schedule.options[0].show = this.isOpenUserStore();
        }
    },
    beforeMount(){
        this.listOnline();
    },
    methods:{

        listOnline(){ //itera las tiendas de la ciudad seleccionada con la consulta de status para verificar su accesibilidad
            this.storesAvailables = {};
            for(let i in this.cityMenu.stores){
                if(this.listStoreStatus.hasOwnProperty(i)){
                    if(this.listStoreStatus[i].status == 'open' || this.listStoreStatus[i].status == 'close'){
                        this.storesAvailables[i] = this.cityMenu.stores[i];
                    }
                }
            }
            if(Object.keys(this.storesAvailables).length <= 0){
                this.cartOrderTransition.sendType.options[1].enabled = false; // deshabilitar la opcion de pickup si no hay tiendas online
            }else{
                this.cartOrderTransition.sendType.options[1].enabled = true;
            }
        },

        numericsData(e, type='direct'){
            if(!e || e == null){
                return ''
            }
            switch(type) {
                case 'direct':
                    // Limpiar el valor y convertirlo a un número entero válido
                    const cleanedValue = e.replace(/[^0-9]/g, '');
                    return cleanedValue === '' ? 0 : parseInt(cleanedValue, 10);
                case 'event':
                    if (e.target && e.target.value !== undefined) {
                        let value = e.target.value;
                        // Limpiar el valor y convertirlo a un número entero válido
                        value = value === '' ? 0 : parseInt(value.replace(/[^0-9]/g, ''), 10);
                        e.target.value = value;
                    }
                    break;
            }
        },

        validateNextness(){ // valida lo necesario para aparecer el boton de siguiente paso en modales
            switch(this.cartOrderSelection.modalPosition){
                case 'sendType':
                    switch(this.cartOrderSelection.sendType.selected){
                        case 'delivery':
                            return this.cartOrderSelection.sendType.value != '' && this.cartOrderSelection.sendType.value != null && (this.cartOrderTransition.sendType.options[0].allow === 2 || this.cartOrderTransition.sendType.options[0].allow === 4)
                            break;
                        case 'pickup':
                            return this.cartOrderSelection.sendType.value != '' && this.cartOrderSelection.sendType.value != null
                            break;
                        default:
                            return false
                            break;
                    }
                    break;
                case 'schedule':
                    switch(this.cartOrderSelection.schedule.selected){
                        case 'now':
                            return this.cartOrderSelection.schedule.value != '' && this.cartOrderSelection.schedule.value != null
                            break;
                        case 'await':
                            if(typeof this.cartOrderSelection.schedule.value === 'object'){
                                if(this.cartOrderSelection.schedule.value.date == ''
                                || this.cartOrderSelection.schedule.value.date == null 
                                || this.cartOrderSelection.schedule.value.hour == ''
                                || this.cartOrderSelection.schedule.value.hour == null){
                                    return false
                                }
                                return true
                            }
                            return this.cartOrderSelection.schedule.value != '' && this.cartOrderSelection.schedule.value != null
                            break;
                        default:
                            return false
                            break;
                    }
                    break;
                case 'payForm':
                    return this.cartOrderSelection.payForm.selected != '' && this.cartOrderSelection.payForm.selected != null
                    break;
                case 'cashMode':
                    return true
                    break;
                default:
                    return false
                    break;
            }
        },

        allowDelivery(){ // valida si es posible la opcion delivery
            let allowed = 0; // sin token de usuario, denegar acceso

            if(this.user.token != null){

                allowed = 1 // con token de usuario pero sin direccion
   
                if(this.user.data.deliveryDir != ''){ //usuario tiene determinado su deliveryDir
                    if(this.user.data.homes.hasOwnProperty(this.user.data.deliveryDir)){ // direccion existe como nombre

                        let userDirection = this.user.data.homes[this.user.data.deliveryDir];

                        if(userDirection.hasOwnProperty('delivery')){

                            if(userDirection.delivery != false){ // tiene servicio

                                if(!this.cityMenu.stores.hasOwnProperty(userDirection.storeNumber)){
                                    allowed = 3 //direccion no corresponde a ciudad seleccionada de la pagina
                                }else{
                                    if(this.listStoreStatus){
                                        if(this.listStoreStatus.hasOwnProperty(userDirection.storeNumber)){
                                            switch(this.listStoreStatus[userDirection.storeNumber].status){
                                                case 'open':
                                                    allowed = 2
                                                    break;
                                                case 'close':
                                                    allowed = 4
                                                    break;
                                                case 'offline':
                                                default:
                                                    allowed = 5
                                                    break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
       
            }
            return allowed
        },

        getAvailableHours(dateString=''){

            let storeID = 0;
            switch(this.cartOrderSelection.sendType.selected){
                case 'delivery': //nombre de direccion del usuario en value
                    storeID = this.user.data.homes.hasOwnProperty(this.cartOrderSelection.sendType.value) ? this.user.data.homes[this.cartOrderSelection.sendType.value].storeNumber : 0;
                    break;
                case 'pickup': //id de la tienda en value
                    storeID = this.cartOrderSelection.sendType.value;
                    break;
            }
            if(this.user.token != null){
                for(let i in this.cityMenu.stores){
                    if(i == storeID){
                        return this.getAvailableHoursList(dateString, Number(this.cityMenu.stores[i].calendar.openPickup), Number(this.cityMenu.stores[i].calendar.closePickup))
                    }
                }
            }
            return {}
        },

        getAvailableHoursList(dateString='', open=13, close=19) {
            if(dateString == '' || dateString == null){
                return {}
            }
            const [day, month, year] = dateString.split('-').map(Number);
            const selectedDate = new Date(year, month - 1, day); 
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0); 
            const allowedHours = Array.from({ length: close - open + 1 }, (_, index) => open + index);

            const selectedDateTime = selectedDate.getTime();
            const currentDateTime = currentDate.getTime();

            // Objeto para almacenar las horas disponibles
            const availableHoursObj = {};

            // Si la fecha es pasada (antes de hoy), retornar un objeto vacío
            if (selectedDateTime < currentDateTime) {
                return {};
            }

            // Si la fecha es hoy, retornar las horas restantes del día que no han pasado todavía
            if (selectedDateTime === currentDateTime) {
                const currentHour = new Date().getHours();
                const minutesUntilNextHour = 60 - new Date().getMinutes(); // minutos hasta la próxima hora
                const nextHour = currentHour + 1;
                
                // Obtener las horas disponibles a partir de la próxima hora con al menos 40 minutos disponibles
                allowedHours.forEach(hour => {
                if (hour > nextHour || (hour === nextHour && minutesUntilNextHour >= 40)) {
                    const formattedHour = hour < 10 ? `0${hour}:00` : `${hour}:00`;
                    availableHoursObj[formattedHour] = {};
                }
                });
                
                return availableHoursObj;
            }

            // Si la fecha es futura pero está dentro del rango permitido, retornar todas las horas permitidas
            if (selectedDateTime > currentDateTime) {
                allowedHours.forEach(hour => {
                const formattedHour = hour < 10 ? `0${hour}:00` : `${hour}:00`;
                availableHoursObj[formattedHour] = {};
                });
                return availableHoursObj;
            }

            // Si la fecha es futura pero está fuera del rango permitido, retornar un objeto vacío
            return {};
        },

        postDates(open=13, close=19, todayEnabled=true){
            const dates = {};
            const today = new Date();

            // Obtener la hora actual y verificar si ya son la hora final de pickup o mas tarde
            // y si faltan menos de 40 minutos para las la hora de cierre de pickup hrs
            const currentHour = today.getHours();
            const currentMinute = today.getMinutes();
            const closedStore = currentHour >= close;
            const nearToCloseStore = (currentHour === (close-1) && currentMinute >= 20); //menos de 20mins

            for (let i = 0; i < 3; i++) {
               
                if (i === 0 && (nearToCloseStore || closedStore || !todayEnabled)) {
                    continue; // ya cerro o esta apunto de cerrar en i==0 (hoy)
                }
                const date = new Date(today);
                date.setDate(date.getDate() + i);
                const dd = String(date.getDate()).padStart(2, '0');
                const mm = String(date.getMonth() + 1).padStart(2, '0');
                const yyyy = date.getFullYear();
                const formattedDate = `${dd}-${mm}-${yyyy}`;
                const day = (i === 0) ? this.phrases.f14[this.lang] : (i === 1) ? this.phrases.f15[this.lang] : this.phrases.f16[this.lang];
                const dateKey = `${yyyy}-${mm}-${dd}`;
                dates[formattedDate] = { day: day, dateKey: dateKey };
            }
            return dates;
        },

        getPickupDates() {
            let storeID = 0;
            let today = false;

            switch(this.cartOrderSelection.sendType.selected){
                case 'delivery': //nombre de direccion del usuario en value
                    storeID = this.user.data.homes.hasOwnProperty(this.cartOrderSelection.sendType.value) ? this.user.data.homes[this.cartOrderSelection.sendType.value].storeNumber : 0;
                    break;
                case 'pickup': //id de la tienda en value
                    storeID = this.cartOrderSelection.sendType.value;
                    break;
            }

            if(this.listStoreStatus){ // revisa si esta abierta la tienda para permitir el 'hoy'
                if(this.listStoreStatus.hasOwnProperty(storeID)){
                    if(this.listStoreStatus[storeID].status == 'open'){
                        today = true
                    }
                }
            }

            if(this.user.token != null){
                for(let i in this.cityMenu.stores){
                    if(i == storeID){
                        return this.postDates(Number(this.cityMenu.stores[i].calendar.openPickup), Number(this.cityMenu.stores[i].calendar.closePickup), today)
                    }
                }
            }
            return {}
        },

        stop(e){
            e.stopImmediatePropagation();
            e.stopPropagation();
        },

        nextStep(){
            this.pending = 1;
            setTimeout(()=>{
                switch(this.cartOrderSelection.modalPosition){
                    case 'sendType':
                        this.$emit('event', 'changeModal', 'schedule')
                        break;
                    case 'schedule':
                        this.$emit('event', 'changeModal', 'payForm')
                        break;
                    case 'payForm':
                        switch(this.cartOrderSelection.payForm.selected){
                            case 'cash':
                                this.$emit('event', 'changeModal', 'cashMode')
                                break;
                            case 'card':
                                this.$emit('event', 'changeModal', 'finish')
                                break;
                            case 'paypal':
                                this.$emit('event', 'changeModal', 'onlineMode')
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'cashMode':
                        this.$emit('event', 'changeModal', 'finish')
                        break;
                    case 'onlineMode':
                        //this.$emit('event', 'changeModal', 'finish')
                        break;
                    case 'storeClosed':
                        this.$emit('event', 'changeModal', 'schedule')
                        break;
                    case 'storeError':
                        this.$emit('event', 'resetModal')
                        break;
                    case 'storeOffline':
                        this.$emit('event', 'resetModal')
                        break;
                }
            }, 100);
            setTimeout(()=>{
                this.pending = 2;
                setTimeout(()=>{
                    this.pending = 0;
                }, 300)
            }, 100)
        },

        close(){
            this.$emit('close')
        },

        selectOption(e=null, id, optionModal, value='', key='', fullOption=false){

            if (event !== null) {
                event.stopPropagation(); // Solo llama stopPropagation si event no es null
            }

            if(this.cartOrderSelection[this.cartOrderSelection.modalPosition].selected == id && this.cartOrderSelection.modalPosition == optionModal && (this.cartOrderSelection[this.cartOrderSelection.modalPosition].value == value || value == null || value == '')){
                if(fullOption){
                    this.$emit('event', 'resetChecks', null, optionModal);
                } 
                return false;
            }
           
            switch(optionModal){
                case 'sendType':
                    switch(id){
                        case 'delivery': //guarda la direccion del usuario como value al darle al checkbox
                            if(value == null && this.user.data.deliveryDir != '' && this.user.data.deliveryDir != null){
                                value = this.user.data.deliveryDir;
                            }
                            break;
                        case 'pickup':
                            if(value==null || value == ''){ // hace reset del selector a 
                                if(this.storesAvailables){
                                    value = Object.keys(this.storesAvailables)[0];
                                    break;
                                }
                            }
                            break;
                    }
                    break;
                case 'schedule':
                    switch(id){
                        case 'now':

                            if(this.cartOrderSelection.sendType.selected == 'delivery'){
                                if(this.user.data.homes.hasOwnProperty(this.cartOrderSelection.sendType.value)){
                                    if(this.listStoreStatus.hasOwnProperty(this.user.data.homes[this.cartOrderSelection.sendType.value].storeNumber)){
                                        if(this.listStoreStatus[this.user.data.homes[this.cartOrderSelection.sendType.value].storeNumber].status == 'open'){
                                            value = (new Date).toLocaleString("en-CA", { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric', hour:'2-digit', minute:'2-digit', second:'2-digit' });
                                            break;
                                        }
                                    }
                                }
                                this.cartOrderTransition.schedule.options[0].enabled = false; // esto en caso de no estar abierta la tienda
                                this.cartOrderTransition.schedule.options[0].show = false;

                            }else if(this.cartOrderSelection.sendType.selected == 'pickup'){
                                if(this.listStoreStatus.hasOwnProperty(this.cartOrderSelection.sendType.value)){
                                    if(this.listStoreStatus[this.cartOrderSelection.sendType.value].status == 'open' || this.listStoreStatus[this.cartOrderSelection.sendType.value].status == 'close'){
                                        value = (new Date).toLocaleString("en-CA", { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric', hour:'2-digit', minute:'2-digit', second:'2-digit' });
                                        break;
                                    }
                                }
                            }else{
                                return false
                            }

                            this.$emit('event', 'resetChecks', null, optionModal);

                            break;
                        case 'await':
                            if(value==null || value == ''){
                                value = {date:"", hour:""}
                            }else if(typeof this.cartOrderSelection[this.cartOrderSelection.modalPosition].value === 'object' ){
                                let nextValue = value;
                                value = this.cartOrderSelection[this.cartOrderSelection.modalPosition].value;
                                value[key] = nextValue;

                                if(key == 'date'){ //validar si es cambio de fecha, para poner la hora del primer indice y evitar horas no validas
                                    value.hour = Object.keys(this.getAvailableHours(nextValue))[0];
                                }
                            }
                            break;
                    }
                    break;
                case 'payForm':
                    switch(id){
                        case 'cash':
                            break;
                    }
                    break;
                case 'cashMode':
                    value = this.numericsData(value);
                    break;
                default:
                    break;
            }
            this.$emit('event', 'selectOption', id, optionModal, value); // asigna a la opcion su valor seleccionado (padre carrito/orders)
        },

        returnToCarLater(){
            this.$emit('event', 'editDirections');
        },

        isOpenUserStore(){
            let storeId = 0;
            switch(this.cartOrderSelection.sendType.selected){
                case 'delivery': //nombre de direccion del usuario en value
                    storeId = this.user.data.homes.hasOwnProperty(this.cartOrderSelection.sendType.value) ? this.user.data.homes[this.cartOrderSelection.sendType.value].storeNumber : 0;
                    break;
                case 'pickup': //id de la tienda en value
                    storeId = this.cartOrderSelection.sendType.value;
                    break;
            }
            if(this.listStoreStatus){
                if(this.listStoreStatus.hasOwnProperty(storeId)){
                    if(this.listStoreStatus[storeId].status == 'open'){
                        return true
                    }
                }
            }
            return false
        },

    },
    data(){
        return{
            storesAvailables:{},
            transition:false,
            pending:false,
            phrases:{
                f1:{
                    es:'EDITAR',
                    en:'EDIT'
                },
                f2:{
                    es:'Continuar',
                    en:'Continue'
                },
                f3:{
                    es:'Realizar Pedido',
                    en:'Send Order'
                },
                f4:{
                    es:'Tiempo Estimado',
                    en:'Estimated Time'
                },
                f5:{
                    es:'Llegada estimada',
                    en:'Estimated arrival'
                },
                f6:{
                    es:'Al finalizar la orden comenzaremos a preparar tu pedido.',
                    en:'Upon completion of the order we will begin prepare your order.'
                },
                f7:{
                    es:'Selecciona el día y hora para recibir tu pedido. El tiempo final podria variar.',
                    en:'Select the day and time to receive your order. Final time may vary.'
                },
                f8:{
                    es:'Insertar valor si requiere cambio.',
                    en:'Insert value if coin change is required.'
                },
                f9:{
                    es:'envío',
                    en:'delivery'
                },
                f10:{
                    es:'recolección',
                    en:'pickup'
                },
                f11:{
                    es:'Seleccionar Surcursal',
                    en:'Choose Store'
                },
                f12:{
                    es:'Dia',
                    en:'Day'
                },
                f13:{
                    es:'Hora',
                    en:'Hour'
                },
                f14:{
                    es:'Hoy',
                    en:'Today'
                },
                f15:{
                    es:'Mañana',
                    en:'Tomorrow'
                },
                f16:{
                    es:'Pasado mañana',
                    en:'Day after tomorrow'
                },
                f17:{
                    es:'500',
                    en:'500'
                },
                f18:{
                    es:'Ir a mi cuenta',
                    en:'Go to my account'
                },
                f19:{
                    es:'Agregar Dirección',
                    en:'Add Address'
                },
                f20:{
                    es:'Tu dirección no tiene cobertura para reparto; Cámbiala o selecciona la opción "Recoger en sucursal".',
                    en:'Your address does not have coverage for delivery, change it or choose the option Store pickup.'
                },
                f21:{
                    es:'Cambiar o editar dirección',
                    en:'Change or edit address'
                },
                f22:{
                    es:'Falta agregar una dirección de envio, edítala en tu cuenta o selecciona la opción "Recoger en sucursal".',
                    en:'You do not have an address for delivery. Please add one or choose the Store Pickup option.'
                },
                f23:{
                    es:'Sin Dirección',
                    en:'Empty Address'
                },
                f24:{
                    es:'Sin Servicio',
                    en:'No Service'
                },
                f25:{
                    es:'Elige una de nuestras sucursales para pasar a recoger tu pedido.',
                    en:'Choose one of our stores to pick up your order.'
                },
                f26:{
                    es:'Por el momento nuesta sucursal se encuentra fuera de horario de servicio, pero puedes programar tu pedido para un horario futuro.',
                    en:'Currently, our store is out of service hours, But you could schedule your order for a future time'
                },
                f27:{
                    es:'Por el momento nuesta sucursal se encuentra desconectada, pero te recomendamos llamar a nuestro callcenter para realizar tu pedido',
                    en:'Currently, our store is offline, but we recommend calling our call center to place your order.'
                },
                f28:{
                    es:'Aceptar',
                    en:'Accept'
                },
                f29:{
                    es:'Nuestra sucursal aún no abre',
                    en:'Our store is not open yet'
                },
                f30:{
                    es:'Sucursal fuera de línea',
                    en:'Offline store'
                },
                f31:{
                    es:'Trae Cambio de',
                    en:'Bring money change for'
                },
                f32:{
                    es:'Falta agregar una dirección válida para la región de la tienda o selecciona la opción "Recoger en sucursal".',
                    en:'You do not have an address for delivery for the city region. Please add one or choose the Store Pickup option.'
                },
                f33:{
                    es:'Tiempo personalizado',
                    en:'Customized Time'
                },
                f34:{
                    es:'Para proteger tu privacidad, solo mostramos la colonia de tu dirección de envio.<br><br class="d-none d-sm-block">Si necesitas ver la dirección completa, verifica tu identidad con un código de WhatsApp.',
                    en:'To protect your privacy, we only show your district. If you need to see the full address, please verify your identity with a WhatsApp code.'
                },
                f35:{
                    es:'VERIFICAR Y EDITAR',
                    en:'VERIFY AND EDIT'
                },
            },

            /* assets */
            a15:require('@/assets/images/a15.svg'),
            a11:require('@/assets/images/a11.svg'),
            a10:require('@/assets/images/a10.svg'),
            a48:require('@/assets/images/a48.svg'),
            a49:require('@/assets/images/a49.svg'),

            /* conf interna */
            cartOrderTransition:{
                
                sendType:{
                    title:{
                        es:'Elige tu forma de envío',
                        en:'Choose your shipping method'
                    },
                    defValue:null, 
                    options:[
                        {
                            simbol:require('@/assets/images/a27.svg'),
                            icon:require('@/assets/images/a11.svg'),
                            show:true,
                            allow:this.allowDelivery(), // esto se podria cambiar watch listStoreStatus -> status de tiendas y en watch de objeto usuario
                            enabled:true,
                            description:{
                                es:'Envío a domicilio',
                                en:'Home delivery'
                            },
                            idOption:'delivery',
                        },
                        {
                            simbol:require('@/assets/images/a27.svg'),
                            icon:require('@/assets/images/a15.svg'),
                            show:true,
                            allow:2,
                            enabled:true, // esto se podria cambiar en la funcion listOnline cuando se actualizan los status de tiendas
                            description:{
                                es:'Recoger en sucursal',
                                en:'Store pickup'
                            },
                            idOption:'pickup',
                        }
                    ]
                },
                schedule:{
                    title:{
                        es:'Horario de envío',
                        en:'Shipping schedule'
                    },
                    defValue:null,
                    options:[
                        {
                            simbol:require('@/assets/images/a27.svg'),
                            icon:require('@/assets/images/a17.svg'),
                            show:this.isOpenUserStore(),
                            allow:2,
                            enabled:this.isOpenUserStore(),
                            description:{
                                es:'Preparar ahora mismo',
                                en:'Prepare right now'
                            },
                            idOption:'now'
                        },
                        {
                            simbol:require('@/assets/images/a27.svg'),
                            icon:require('@/assets/images/a16.svg'),
                            show:true,
                            allow:2,
                            enabled:true,
                            description:{
                                es:'Programar',
                                en:'Schedule'
                            },
                            idOption:'await',
                        }
                    ],
                },
                payForm:{
                    title:{
                        es:'Forma de pago',
                        en:'method of payment'
                    },
                    defValue:0,
                    options:[
                        {
                            simbol:require('@/assets/images/a27.svg'),
                            icon:require('@/assets/images/a29.svg'),
                            show:true,
                            allow:2,
                            enabled:true,
                            description:{
                                es:'Pago en efectivo',
                                en:'Cash payment'
                            },
                            idOption:'cash',
                        },
                        {
                            simbol:require('@/assets/images/a27.svg'),
                            icon:require('@/assets/images/a30.svg'),
                            show:true,
                            allow:2,
                            enabled:true,
                            description:{
                                es:'Enviar terminal bancaria',
                                en:'Send bank terminal'
                            },
                            idOption:'card',
                        },
                        {
                            simbol:require('@/assets/images/a27.svg'),
                            icon:require('@/assets/images/a28.svg'),
                            show:false,
                            allow:2,
                            enabled:false,
                            description:{
                                es:'Pagar con paypal',
                                en:'Pay with paypal'
                            },
                            idOption:'online',
                        }
                    ]
                },
                cashMode:{
                    title:{
                        es:'Forma de pago',
                        en:'method of payment'
                    },
                    defValue:0,
                    options:[
                        {
                            simbol:require('@/assets/images/a27.svg'),
                            icon:require('@/assets/images/a29.svg'),
                            show:true,
                            allow:2,
                            enabled:true,
                            description:{
                                es:'Pago en efectivo',
                                en:'Cash payment'
                            },
                            idOption:'cash',
                        }
                    ]
                },
                onlineMode:{
                    title:{
                        es:'Pago en línea',
                        en:'Pay online'
                    },
                    defValue:0,
                    options:[]
                },
                storeClosed:{
                    title:{
                        es:'Sucursal Cerrada',
                        en:'Store Closed'
                    },
                    defValue:0,
                    options:[]
                },
                storeError:{
                    title:{
                        es:'Sucursal Desconectada',
                        en:'Store Disconected'
                    },
                    defValue:0,
                    options:[]
                }
            },
        }
    }
}
</script>
<style lang="css" scoped>
    .colonyText{
        font-size: 20px;
        color:var(--text13)
    }
    .cpText{
        color:var(--text17)
    }
    .dirComplete{
        font-size: 18px;
        color:var(--text17)
    }
    .descriptionText{
        font-size: 18px;
        color:var(--text17)
    }
    .editButton{
        font-size: 14px;
        color: var(--text7);
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }
    .hiddenInfoLabel{
        font-size: 14px;
        color: var(--text17);
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }
    .titleOption{
        font-size: 24px;
        flex-shrink: 0;
    }
    .titleOptionError{
        font-size: 28px;
        flex-shrink: 0;
    }
    .subtitled{
        font-size: 18px;
        color: var(--text24);
    }
    .callcenterText{
        font-size: 22px;
        flex-shrink: 0;
        color: var(--text9);
    }
    .checkBoxArea{
        padding-top: 19px;
        height: 100%;
        flex-shrink: 0;
    }
    .option:hover .checkBoxArea .commonPageCheckbox input:not(:checked) + label{
        border: 3px solid var(--secondary);
    }
    .checkBoxArea .commonPageCheckbox label{
        width: 30px;
        height: 30px;
        border-radius: 6px;
        overflow: visible;
    }
    .checkBoxArea .commonPageCheckbox img{
        width: 100%;
        height: 100%;
        transform: scaleX(2) scaleY(2) translateY(-10%);
    }
    .commonPageCheckbox span{
        background-color: transparent;
    }
    /*.icon{
        padding-top: 12px;
        width: 70px;
        height: 100%;
        flex-shrink: 0;
    }*/
    .option[enabled='false']{
        pointer-events: none;
        opacity: 0.25 !important;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;   
        user-select: none;
    }
    .option{
        cursor: pointer;
    }
    .option.notSelected:hover .dataArea{
        opacity: 0.95 !important;
    }
    .option.notSelected {
        opacity: 0.75;
        display: none !important;
    }
    #orderSteps{
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 4000;
        position: fixed;
        top:0;
        left:0;
    }
    .rel{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .floatRectangle{
        width: 750px;
        max-width: 96%;
        height: auto;
        overflow-y:visible;
        overflow-x: visible;
        max-height:85vh;
        position: absolute;
        align-content: center;
        align-items:center;
        animation:bounce 0.2s linear 1 forwards; 
    }
    
    .floatRectangle > .rel{
        background-color: #fff;
        overflow-y:visible;
        overflow-x: visible;
    }
    @media (max-width: 768px) {  
        .floatRectangle > .rel{
            overflow-y:auto
        }
        .floatRectangle{
            height: 100vh;
            max-width: 100%;
            max-height:100vh;
        }
    }
    .floatRectangle > .rel.in{
        animation:indoor 0.3s linear 1 forwards;
    }
    .floatRectangle > .rel.out{
        animation:outdoor 0.3s linear 1 forwards;
    }
    .floatRectangle .headerModal{
        background-color: var(--primary)

    }
    .titleSelect{
        line-height: 1.2;
        padding-top: 5px;
        color:var(--text2);
        word-wrap: break-word;
        word-break: break-word;
        z-index: 2;
    }
    .closeButton{
        position: absolute;
        right: 0;
        margin: auto;
        color:var(--text10);
        z-index: 5;
        border-left: 2px solid var(--vigequinary);
    }
    .closeButton:hover svg{
        transform: scale(1.2);
        filter: contrast(0.9)
    }
    .closeButton svg{
        pointer-events: none;
        height: 25px;
    }

    ::v-deep(.closeButton svg path){
        fill: #fff;
    }

    ::v-deep(.posIcon){
        width:60px;
        height: 60px
    }

    .storeOption input[type="radio"] {
        display: none;
      }
      
      .storeOption label {
        font-size: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        align-content: center
      }
      
      .storeOption input[type="radio"] + label .circle {
        content: '';
        position: relative;
        width: 22px; /* ajusta el tamaño del botón del radio */
        height: 22px; /* ajusta el tamaño del botón del radio */
        border: 1px solid #999;
        border-radius: 50%;
        display: inline-flex;
        background-color: #fff;
        align-items: center;
        align-content: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0
      }
      
      .storeOption input[type="radio"]:checked + label .circle::after {
        content:"";
        display: block;
        border-radius: 50%;
        width: 12px; /* ajusta el tamaño del botón del radio */
        height: 12px; /* ajusta el tamaño del botón del radio */
        background-color: var(--primary); /* cambia el color del botón del radio cuando está seleccionado */
      }

    .timeLabel{
        color:var(--text11);
        line-height: 1.2;
        font-size: 60px;
    }

    .subTimeLabel{
        color:var(--text11);
        font-size: 14px;
    }

    .timeIndicator{
        flex-shrink: 0;
    }

    .dataArea{
        width: calc(100% - 30px);
    }

    .buttonNext{
        font-size:20px !important;
        min-height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    .borderedBottom{
        border-bottom: 1px solid var(--nonagenary);
    }
      

</style>